<template>
	<div class="page_content">
        <div class="page_detail">
            <a-divider orientation="left">基本信息</a-divider>
            <a-descriptions :column="2">
                <a-descriptions-item label="供应商名称">
                    {{ detail.supplier || '--' }}
                </a-descriptions-item>
                <a-descriptions-item label="供应商编码">
                    {{ detail.supplier_code || '--' }}
                </a-descriptions-item>
                <a-descriptions-item label="城市">
                    {{ detail.city || '--' }}
                </a-descriptions-item>
                <a-descriptions-item label="邮政编码">
                    {{ detail.postal_code || '--' }}
                </a-descriptions-item>
                <a-descriptions-item label="状态">
                    <span v-if="detail.status == 1">启用</span>
                    <span v-else style="color:red;">禁用</span>
                </a-descriptions-item>
                <a-descriptions-item label="创建时间">
                    {{ detail.create_time || '--' }}
                </a-descriptions-item>
            </a-descriptions>

            <a-divider orientation="left">联系人</a-divider>
            <div style="display:flex;justify-content:space-between;margin-bottom:15px;">
                <div><a-button @click="getAddSave('','add')" type="primary">新增联系人</a-button></div>
                <div><!-- 占位 --></div>
            </div>
            <a-table :columns="columns" :data-source="detail.contact_info" :pagination="false">
                <template slot="bind_status" slot-scope="data">
                    <span>{{ data > 0 ? "已绑定" : "未绑定" }}</span>
                </template>
                <template slot="operation" slot-scope="data">
                    <a @click="getAddSave(data,'edit')">编辑</a>
                    <a-divider type="vertical" />
                    <a @click="getDelete(data)">删除</a>
                </template>
            </a-table>

            <div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
        </div>

        <!-- 添加或编辑联系人 -->
        <a-modal v-model:visible="contactsShow" :title="contactsFrom.id ? '编辑联系人信息' : '新增联系人信息'" @ok="contactsOk">
            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item required name='name' label="联系人名称" :rules="[{ required: true, message: '联系人名称不能为空',tagger:'blur' }]">
                    <a-input v-model:value="contactsFrom.name" placeholder="请输入联系人名称" />
                </a-form-item>
                <a-form-item required name='name' label="联系人电话" :rules="[{ required: true, message: '联系人电话不能为空',tagger:'blur' }]">
                    <a-input v-model:value="contactsFrom.phone" placeholder="请输入联系人电话" />
                </a-form-item>
            </a-form>
        </a-modal>

	</div>
</template>

<script>
import {message,Modal} from 'ant-design-vue'
import {getSupplierDetail,saveContactInfo,delContactInfo} from '@/api/qms.js'

const columns = [
    {
        title: "联系人名称",
        dataIndex: "name",
    },
    {
        title: "联系电话",
        dataIndex: "phone",
    },
    {
        title: "微信OpenID",
        dataIndex: "openid",
    },
    {
        title: "是否绑定",
        dataIndex: "bind_status",
        scopedSlots: {
            customRender: "bind_status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
]

export default {
    components: {
        
    },
    data() {
        return {
            columns,
            detail:'',
            query:'',

            labelColEdit: {
                span: 6
            },
            wrapperColEdit: {
                span: 16
            },

            contactsShow:false,
            contactsFrom:{
                id:'',
                supplier_id:'',
                name:'',
                phone:'',
            },
        }
    },
    created() {
        this.query = this.$route.query
        this.get_info()
    },
    methods: {
        get_info(){
            getSupplierDetail({
                data: {
                    id: this.query.id,
                }
            }).then(res => {
                this.detail = res.data.list
            })
        },

        getAddSave(info,type){
            //新增
            if(type == 'add'){
                this.contactsFrom = {}
                this.contactsShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.defaultArea = []
                this.contactsFrom.id = info.id
                this.contactsFrom.name = info.name
                this.contactsFrom.phone = info.phone
                this.contactsShow = true
            }
        },

        contactsOk(){
            this.contactsFrom.supplier_id = this.detail.id
            saveContactInfo({
                ...this.contactsFrom
            }).then(res=>{
                this.contactsShow = false
                message.success('保存成功');
                this.get_info()
            })
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除联系人【'+info.name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delContactInfo({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.get_info()
                    })
                }
            });
        },

    }
}
</script>

<style lang="less" scoped>
	.page_detail {
		margin: 0;
		margin-left:50px;
	}
    
</style>
